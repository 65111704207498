import Vue from 'vue'
import Router from 'vue-router'
import PageIndex from "@/components/PageIndex";
import PageReg from "@/components/PageReg";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'PageIndex',
            component: PageIndex
        },
        {
            path: '/reg',
            name: 'PageReg',
            component: PageReg
        },
    ]
})
