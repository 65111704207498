<template>
  <div class="layout">
    <div class="index-main">
      <div class="index-content" >
            <div class="index-container ivu-row ivu-row-flex ivu-row-flex-middle ivu-row-flex-center ivu-row-middle ivu-row-center">

              <div class="ivu-col ivu-col-span-24">
                <span class="ivu-badge">
                  <p>HuntingKit</p>
                  <sup class="ivu-badge-count" style="margin-bottom: auto;">Beta v0.1</sup>
                </span>
                <div class="index-content-small">免费的全流量离线威胁分析系统</div>
                <div class="index-actions">
                  <a href="mailto:info@0376.ac.cn" class="ivu-btn ivu-btn-primary ivu-btn-circle">
                    <span>申请试用</span>
                  </a>
                </div>
              </div>
            </div>
      </div>
    </div>
    <div class="index-footer">
      <footer style="color: black">Copyright © 2021 www.0376.ac.cn
      <a href="http://beian.miit.gov.cn" style="color: black">豫ICP备2021013099号</a>
      </footer>
    </div>
</div>

</template>

<script>
export default {
  name: "PageIndex"
}
</script>

<style scoped>
.layout{
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background:white;
  background-image:url(../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden
}
.index-main{
  width: 50%;
  height: 100%;
}
.index-content{
  text-align: left;
  margin:380px 10px 30px 150px;
  padding: 10px 40px 10px 40px;
  position: absolute;
}
p {
  font-family:"PingFang SC";
  font-weight: bolder;
  font-size: 40px;
  color: #2c3e50;
}

.index-content-small {
  font-size: 24px;
  margin-top: 10px;
  padding-left: 16px;
}
.index-actions {
  margin-top: 80px;
  margin-bottom: 150px;
  margin-left: 16px;
}
.index-footer{
  width: 100%;
  position: fixed;
  bottom: 10px;
  text-align: center;
  color: #c5c8ce;
}
</style>
