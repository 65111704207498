import Vue from 'vue'
import App from './App.vue'
import router from './router'
import iView from 'iview';
import 'iview/dist/styles/iview.css';


Vue.use(iView)
Vue.config.productionTip = false

new Vue({
  el:'#app' ,
  router,
  components: {
    App
  },
  render: h => h(App),
}).$mount('#app')
