<template>
  <div class="layout">
    <div class="index-main">
      <div class="index-reg">
        <h1>试用申请</h1>
        <Form :model="formItem" :label-width="80">
          <FormItem label="客户名称:">
            <Input type="text" v-model="formItem.vendor" placeholder="客户名称" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="联系人:">
            <Input type="text" v-model="formItem.name" placeholder="联系人" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="联系电话:">
            <Input type="text" v-model="formItem.tel" placeholder="联系电话" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="邮件:">
            <Input type="email" v-model="formItem.email" placeholder="邮件" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="留言:">
            <Input v-model="formItem.textarea" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="Enter something..."></Input>
          </FormItem>
          <FormItem>
            <Button type="primary">提交</Button>

          </FormItem>
        </Form>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PageReg",
  data () {
    return {
      formItem: {
        vendor:'',
        name: '',
        tel:'',
        email:'',
        textarea: ''
      }
    }
  }
}
</script>

<style scoped>
.layout{
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background:white;
  background-image:url(../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden
}
.index-main{
  width: 50%;
  height: 100%;
}
.index-reg{
  border: 1px solid #DCDFE6;
  margin:380px 650px 380px 650px;
  padding: 10px 40px 10px 40px;
  position: absolute;
}
</style>
